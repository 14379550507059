import React from 'react'
import Layout from '../layout/Layout'

const Group = () => {
  return (
    <div>
          <Layout>
        Group
        </Layout>
    </div>
  )
}

export default Group
